import React from "react";
import { useLocation } from "react-router-dom";

const CopyDetails = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const fileName = query.get("file_name");
    const sessionSignature = query.get("session_signature");

    const handleCopy = () => {
        const text = `File Name: ${fileName}\nSession Code: ${sessionSignature}`;
        navigator.clipboard.writeText(text);
        alert("Details copied to clipboard!");
    };

    return (
        <div className="container mt-5">
            <div className="card shadow p-4">
                <h2 className="text-center mb-4 text-primary">File Details</h2>
                <div className="mb-3">
                    <h5><strong>File Name:</strong></h5>
                    <p className="form-control bg-light">{fileName}</p>
                </div>
                <div className="mb-3">
                    <h5><strong>Session Code:</strong></h5>
                    <p className="form-control bg-light">{sessionSignature}</p>
                </div>
                <button
                    className="btn btn-primary btn-lg w-100"
                    onClick={handleCopy}
                >
                    Copy Details
                </button>
            </div>
        </div>
    );
};

export default CopyDetails;