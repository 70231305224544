import React, { useState } from "react";
import { generatePresignedUrl } from "../utils/api";
import { QRCodeCanvas } from "qrcode.react";

const FileUploader = () => {
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState("No file uploaded");
    const [shareableLink, setShareableLink] = useState("");
    const [QrLink, SetQrLink] = useState("");
    const [fastShare, setFastShare] = useState(false);
    const [showQR, setShowQR] = useState(false);

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        setProgress(0);
        setStatus("Preparing file for upload...");

        try {
            let fileToUpload = selectedFile;
            let fileName = selectedFile.name;

            // Step 1: Request pre-signed URL
            setStatus("Generating upload link...");
            const { upload_url, fields, job_id, session_signature } = await generatePresignedUrl(fileName);

            setStatus("Uploading file...");
            const formData = new FormData();
            Object.entries(fields).forEach(([key, value]) => formData.append(key, value));
            formData.append("file", fileToUpload);

            // Step 2: Upload the file
            const xhr = new XMLHttpRequest();
            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    const percentComplete = Math.round((event.loaded / event.total) * 100);
                    setProgress(percentComplete);
                }
            });

            xhr.onload = () => {
                if (xhr.status === 204) {
                    setStatus("File uploaded successfully!");
                    const constructed_file_name = job_id + fileName;
                    const link = `${window.location.origin}/recieve?file_name=${constructed_file_name}&session_signature=${session_signature}`;
                    const Qrlink = `${window.location.origin}/copy-details?file_name=${constructed_file_name}&session_signature=${session_signature}`;
                    setShareableLink(link);
                    SetQrLink(Qrlink);
                    setShowQR(true); // Display QR code after upload
                } else {
                    setStatus("Upload failed");
                    setProgress(0);
                }
            };

            xhr.onerror = () => {
                setStatus("An error occurred during upload");
                setProgress(0);
            };

            xhr.open("POST", upload_url);
            xhr.send(formData);
        } catch (error) {
            console.error("Error:", error.message);
            setStatus("Upload failed");
            setProgress(0);
        }
    };

    return (
        <div className="container mt-5">
            <div className="card shadow p-4">
                <h2 className="text-center mb-4">Upload a File</h2>
                <div className="form-check mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="fastShare"
                        checked={fastShare}
                        onChange={(e) => setFastShare(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="fastShare">
                        Enable Fast Share (Note: Only for files up to 1.5 GB)
                    </label>
                </div>
                <div className="mb-3">
                    <input type="file" className="form-control" onChange={handleFileChange} />
                </div>
                {progress > 0 && (
                    <div className="progress mb-3" style={{ height: "25px" }}>
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                        >
                            {progress}%
                        </div>
                    </div>
                )}
                <p className={`alert ${status === "File uploaded successfully!" ? "alert-success" : "alert-info"}`}>
                    Status: {status}
                </p>
                {showQR && shareableLink && (
                    <div className="mt-4 text-center">
                        <h5>Scan to Copy Details:</h5>
                        <QRCodeCanvas value={QrLink} size={256} />
                        <p className="mt-2 text-break">{shareableLink}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileUploader;