// const BASE_URL = "https://api.shareasy.in/api"; // Replace with your backend's URL
const BASE_URL = "https://5i7ar1uha3.execute-api.ap-south-1.amazonaws.com/default"; // Replace with your backend's URL

// Generate a presigned URL for uploading
export const generatePresignedUrl = async (fileName) => {
    const response = await fetch(`${BASE_URL}/shareasy-upoad`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ file_name: fileName }),
    });

    if (!response.ok) {
        throw new Error("Failed to generate presigned URL");
    }

    return response.json();
};

// Download a file using the unique link
export const downloadFile = async (fileName, sessionSignature) => {
    console.log("sending req")
    const response = await fetch(`${BASE_URL}/shareasy-download`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ file_name: fileName, session_signature: sessionSignature }),
    });

    if (!response.ok) {
        throw new Error("Failed to download file");
    }

    return response.json();
};

export const downloadFileByCode = async (uniqueCode) => {
    const response = await fetch(`${BASE_URL}/download/by-code`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ unique_code: uniqueCode }),
    });

    if (!response.ok) {
        throw new Error("Failed to download file");
    }

    return response.json();
};