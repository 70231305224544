import React, { useState } from "react";
import { downloadFile } from "../utils/api";

const SelfDownloadFile = () => {
    const [fileName, setFileName] = useState("");
    const [sessionSignature, setSessionSignature] = useState("");
    const [error, setError] = useState("");

    const handleDownload = async () => {
        try {
            const { download_url } = await downloadFile(fileName, sessionSignature);
            window.location.href = download_url;
        } catch (err) {
            setError("Failed to download file. Please check the details.");
        }
    };

    return (
        <div className="container mt-5">
            <div className="card shadow p-4">
                <h2 className="text-center mb-4 text-primary">Download File</h2>
                <form>
                    <div className="mb-3">
                        <label htmlFor="fileName" className="form-label"><strong>File Name:</strong></label>
                        <input
                            type="text"
                            id="fileName"
                            className="form-control"
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value)}
                            placeholder="Enter File Name"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="sessionSignature" className="form-label"><strong>Session Code:</strong></label>
                        <input
                            type="text"
                            id="sessionSignature"
                            className="form-control"
                            value={sessionSignature}
                            onChange={(e) => setSessionSignature(e.target.value)}
                            placeholder="Enter Session Code"
                        />
                    </div>
                    <button
                        type="button"
                        className="btn btn-primary btn-lg w-100"
                        onClick={handleDownload}
                    >
                        Download File
                    </button>
                </form>
                {error && <p className="text-danger text-center mt-3">{error}</p>}
            </div>
        </div>
    );
};

export default SelfDownloadFile;